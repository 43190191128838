import PropTypes from 'prop-types'

export const TopicPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
})

export const OrganizationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  slug: PropTypes.string,
  name: PropTypes.string,
  commentsEnabled: PropTypes.bool,
  messagesEnabled: PropTypes.bool,
  votesEnabled: PropTypes.bool,
})

export const OrganizationCardPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  mobileLogoUrl: PropTypes.string.isRequired,
})

export const MeetingPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  organization: OrganizationPropType,
})

export const AgendaItemPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  agendaSequence: PropTypes.string
})

export const EmailPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  subjectRedacted: PropTypes.string.isRequired,
  bodyPlainRedacted: PropTypes.string.isRequired,
  organization: OrganizationPropType,
})

export const MessagePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  author: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  organization: OrganizationPropType,
  messagesByPostId: PropTypes.array.isRequired,
})

export const UserPropType = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  photoUrl: PropTypes.string
})

export default {
  OrganizationPropType,
  MeetingPropType,
  AgendaItemPropType,
  EmailPropType,
  MessagePropType,
  TopicPropType,
  UserPropType
}
