import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'

import { getApolloClient } from '~/pages/_app'
import FormLayout from '~/components/shared/Layout/Form'
import OrganizationCard from '~/components/shared/OrganizationCard'
import { OrganizationCardPropType } from '~/utils/prop-types'
import { GetOrganizationsInfo } from '~/graphql/PublicOrganizations.gql'
import withLocale from '~/hocs/locale'

import styles from './index.module.scss'

const Page = ({ organizations, title, subtitle }) => (
  <FormLayout title="Home | Hiago" pageTitle={title} large className={styles.formLayout}>
    <div className={`${styles.subtitle} text-center mb-5`}>{subtitle}</div>
    <Row>
      {organizations.map(organization => (
        <Col key={organization.id} md={6} className="mb-5">
          <OrganizationCard organization={organization} />
        </Col>
      ))}
    </Row>
  </FormLayout>
)

Page.propTypes = {
  organizations: PropTypes.arrayOf(OrganizationCardPropType).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export async function getServerSideProps(ctx) {
  const client = getApolloClient({ ctx })
  const { data } = await client.query({
    query: GetOrganizationsInfo,
    options: {
      fetchPolicy: 'network-first',
    },
  })

  const organizations = data.organizations.nodes
  const errorCode = !organizations ? 404 : false

  return {
    props: {
      organizations,
      errorCode,
    },
  }
}

export default withLocale('home')(Page)
