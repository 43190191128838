import PropTypes from 'prop-types'
import Layout from '~/components/shared/Layout'
import PageTitle from '~/components/PageTitle'
import styles from './Form.module.scss'

const FormLayout = ({ title, pageTitle, children, className, large }) => {
  return (
    <Layout
      title={title}
      area="main"
      className={`col-md-8 col-lg-6 ${large ? styles.top : 'mt-4'} ${styles.container} ${className}`}
    >
      <section className={styles.content}>
        <PageTitle className="text-center mb-3">{pageTitle}</PageTitle>
        {children}
      </section>
    </Layout>
  )
}

FormLayout.propTypes = {
  title: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  large: PropTypes.bool,
}

FormLayout.defaultProps = {
  className: '',
  large: false,
}

export default FormLayout
