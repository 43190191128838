import Card from 'react-bootstrap/Card'

import { Router } from '~/i18n'
import { OrganizationCardPropType } from '~/utils/prop-types'
import styles from './index.module.scss'

const OrganizationCard = ({ organization }) => {
  const handleCardClick = () => {
    Router.push(`/organizations/${organization.slug}`)
  }

  return (
    <Card className={styles.card} onClick={handleCardClick}>
      <Card.Body className="text-center text-uppercase">
        <img
          src={organization.mobileLogoUrl}
          alt={`${organization.name} Logo`}
          className={`text-center mb-3 ${styles.img}`}
        />
        <Card.Title className="text-dark">{organization.name}</Card.Title>
        <Card.Subtitle className="text-muted">{organization.state}</Card.Subtitle>
      </Card.Body>
    </Card>
  )
}

OrganizationCard.propTypes = {
  organization: OrganizationCardPropType.isRequired,
}

export default OrganizationCard
